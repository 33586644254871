<template>
    <v-container>
        <v-layout align-center justify-center>
            <v-flex xs12 sm8 md4>
                <v-card class="elevation-12">
                    <v-toolbar dark color="primary">
                        <v-toolbar-title>Editar router</v-toolbar-title>
                        <v-spacer></v-spacer>
                    </v-toolbar>
                    <v-card-text>
                        <v-form @submit.prevent="enviarSerial" id="altas-form">
                            <v-text-field
                                    name="formcircuito"
                                    label="Circuito"
                                    type="text"
                                    v-model="formcircuito"
                                    disabled
                                    ></v-text-field>
                            <v-text-field
                                    name="formserial"
                                    label="Nº serie do router"
                                    type="text"
                                    v-model="formserial"
                                    ></v-text-field>
                        </v-form>
                    </v-card-text>
                    <v-alert
                            :value="okAlert"
                            type="success"
                            transition="scale-transition"
                            >OK! Nº de serie actualizado.</v-alert>
                    <v-alert
                            :value="NotFoundAlert"
                            type="info"
                            transition="scale-transition"
                            >Vaia! Non se atopa o circuito.</v-alert>
                    <v-alert
                            :value="serialAlert"
                            type="error"
                            transition="scale-transition"
                            >Error! Non se puido actualizar o nº de serie.</v-alert>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                                id="enviarSerial"
                                class="v-btn-serial"
                                @click="enviarSerial"
                                color="primary"
                                type="submit"
                                form="check-serial-form"
                                >Gardar
                        </v-btn>
                        <v-btn
                            id="volverHistorico"
                            class="v-btn-serial"
                            @click="volver"
                            color="primary"
                            >Volver
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
export default {
    data () {
        return {
            token: '',
            url: process.env.VUE_APP_API_URL,
            formcircuito: '',
            formserial: '',
            okAlert: false,
            NotFoundAlert: false,
            serialAlert: false
        }
    },
    methods: {
        volver () {
            this.$router.push('/historico')
        },
        rellenarDatosRouter () {
            this.formcircuito = this.$store.getters.getCircuitoFtth
            this.formserial = this.$store.getters.getOntSerial
        },
        enviarSerial () {
            const goSerial = async () => {
                const config = {
                    headers: {
                        Accept: 'application/json',
                        Authorization: this.$store.getters.getToken
                    }
                }
                const data = {
                    ftth_circuito: this.formcircuito,
                    ont_serial: this.formserial
                }
                try {
                    return await this.$axios.post(
                        this.url + '/instalador/router/',
                        data,
                        config)
                } catch (error) {
                    if (error.request.status === 401) {
                        this.$router.push('/')
                    } else {
                        this.$data.okAlert = false
                        this.$data.NotFoundAlert = false
                        this.$data.serialAlert = true
                    }
                }
            }

            const processSerialResult = async () => {
                const serial_result = await goSerial()
                if (serial_result) {
                    switch (serial_result.status) {
                    case 200:
                        this.$data.okAlert = true
                        this.$data.NotFoundAlert = false
                        this.$data.serialAlert = false
                        break
                    case 204:
                        this.$data.okAlert = false
                        this.$data.NotFoundAlert = true
                        this.$data.serialAlert = false
                        break
                    default:
                        this.$data.okAlert = false
                        this.$data.NotFoundAlert = false
                        this.$data.serialAlert = true
                        break
                    }
                }
            }
            processSerialResult()
        },
        checkIfLoggedIn () {
            if (this.$store.getters.isLoggedIn === false) {
                this.$router.push('/')
            }
        }
    },
    beforeMount () {
        this.checkIfLoggedIn()
    },
    created () {
        this.rellenarDatosRouter()
    }
}
</script>
